import React, { useLayoutEffect } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import loadable from '@loadable/component';
import { Link } from 'gatsby';
import { CONTACT_US } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPiggyBank,
  faTools,
  faUniversalAccess,
} from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const LandingSubscriptionSection = ({ hash = '' }) => {
  const styles = useStyles();

  useLayoutEffect(() => {
    const waitScroll = async () => {
      const scrollTo = await import('gatsby-plugin-smoothscroll');
      setTimeout(() => {
        scrollTo.default(hash);
      }, 2500);
    };
    if (hash) {
      waitScroll();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <span id="inclusive-features"/>
      <Grid spacing={1}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
       >
        <Grid item xs={12} md={10} lg={8}>
          <Paper className={styles.paper} elevation={4}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            >
              <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h3"
                align="center"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                 Inclusive Features
                <FontAwesomeIcon icon={faTools} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Fully featured at all subscriptions
                </Typography>
              </Typography>
              </Grid>

              <Grid item md={10} sm={12} xs={12}>
              <span id="affordable-fair"/>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
              {'Total Loyalty was built with your business in mind. We use '}
              <a
                href="https://support.apple.com/en-gb/HT204003"
                target="_blank" rel="noopener noreferrer"
                aria-label='Find out more about Apple Wallet'>
                {'Apple Wallet'}
              </a>
                {' and '}
              <a
                href="https://pay.google.com/intl/en_uk/about/learn/"
                target="_blank" rel="noopener noreferrer"
                aria-label='Find out more about Google Pay'>
                {'Google Pay'}
              </a>
              {` loyalty cards for the native features available, like screen alerts and updatable
                content. Our team of developers are working hard to provide new functionality and improved features to get the most from
                Apple Wallet and Google Pay technologies.  As new features become available we promise`}<strong>{' all '}</strong>
                {'subscription levels will always get access to theses great new features at no extra cost, giving your business the tools it needs to build better customer loyalty.'}
              </Typography>
              </Grid>

              <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h3"
                align="center"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                  Affordable & Fair
                <FontAwesomeIcon icon={faPiggyBank} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Affordable solution
                </Typography>
              </Typography>
              </Grid>

              <Grid item md={10} sm={12} xs={12}>
              <span id="no-commitment"/>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                {`We want your business to access all the great features Total Loyalty offers at a price that suits your budget.
                  Total Loyalty charges for the cards your customers are actively using. We only count the cards that are downloaded or used in Loyalty transaction to stamp or redeem in a month as an active card.
                  Your customers might not use your buiness every month and we don't think you should pay for cards that aren't being used. Pay for 100 active cards and have 500 out in the world.
                  The Loyalty cards that aren't helping your business, the cards not being used by your Loyal customers, you won't be charged for them.`}
              </Typography>
              </Grid>

              <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h3"
                align="center"
                className={classnames(styles.headerGreen, styles.fourTopBotMargin)}>
                 No Commitment
                <FontAwesomeIcon icon={faUniversalAccess} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="p"
                  align="right"
                  className={styles.headerSubText}>
                  Simple subscriptions, cancel or change anytime
                </Typography>
              </Typography>
              </Grid>

              <Grid item md={10} sm={12} xs={12}>
              <Typography variant="subtitle1" component="p" align="justify" className={styles.body}>
                {`Total Loyalty won't hold you to a contract.  There are no cancellation fees and is no long term commitment. If our product isn't right for your
                business you can cancel at any time. We can't refund the time you've paid for, our service is billed monthly.
                You can change your subscription level at anytime as your business changes.  If we don't offer a package that suits your needs please get in touch `}
                <Link to={CONTACT_US}>by email</Link>
                {' or '}
                <a
                  href="https://www.facebook.com/totes.loyalty"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label='Find us on Facebook'>
                  chat with us on Facebook
                </a>
                {'.'}
              </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

LandingSubscriptionSection.propTypes = { hash: string };

export default LandingSubscriptionSection;
